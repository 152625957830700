import React from "react";

import Layout from "../layout/layout";
import "../scss/pages/research.scss";

const Research = () => {
  return (
    <Layout>
      <div
        className="content"
        style={{ margin: "auto", marginTop: "100px", maxWidth: "70vw" }}
      >
        <section id="talks">
          <h1 className="SectionHeader">Featured Talks</h1>
          <dl>
            <dt>
              [<a name="2020-compute-choppella-et-al">1</a>]
            </dt>
            <dd>
              Virtual Labs: Insights, Initiatives and Building a Community. In{" "}
              <em>COMPUTE 2020</em>
            </dd>
          </dl>
        </section>
        <section id="publications">
          <h1 className="SectionHeader">Publications</h1>
          <dl>
            {/* <dt>
              [<a name="2019-t4e-chaudhuri-et-al">23</a>]
            </dt>
            <dd>
              Subhrojyoti Roy Chaudhuri, Amar Banerjee, N. Swaminathan,
              Venkatesh Choppella, Arpan Pal, and P. Balamurali. A knowledge
              centric approach to conceptualizing robotic solutions. In{" "}
              <em>
                Proceedings of the 12th Innovations on Software Engineering
                Conference (Formerly Known As India Software Engineering
                Conference), ISEC'19
              </em>
              , pages 12:1--12:11. ISEC, 2019.
            </dd> */}
            <dt>
              [<a name="2019-t4e-pillutla-et-al-usage">22</a>]
            </dt>
            <dd>
              R. S. Pillutla, V. Choppella, L. M. S, M. Dammaraju and P. Raman,
              “Enhancing Virtual Labs Usage in Colleges,”{" "}
              <em>
                2019 IEEE Tenth International Conference on Technology for
                Education (T4E)
              </em>
              , pages 158--161. IEEE, 2019.
            </dd>
            <dt>
              [<a name="2019-t4e-pillutla-et-al">21</a>]
            </dt>
            <dd>
              R. S. Pillutla, L. M. S, V. Choppella, A. Jesrani, P. Raman and Y.
              Raghu Reddy, “Towards Massively Open Online Virtual Internships in
              Computing Education,”{" "}
              <em>
                2019 IEEE Tenth International Conference on Technology for
                Education (T4E)
              </em>
              , pages 90--93. IEEE, 2019.
            </dd>
            <dt>
              [<a name="2019-t4e-kumar-et-al">20</a>]
            </dt>
            <dd>
              M. Kumar, V. Choppella, S. Sunil and S. Syed, “A
              Learnable-by-Design (LEAD) Model for Designing Experiments for
              Computer Science Labs,”{" "}
              <em>
                2019 IEEE Tenth International Conference on Technology for
                Education (T4E)
              </em>
              , pages 222--229. IEEE, 2019.
            </dd>
            <dt>
              [<a name="2019-iticse-kumar-et-al">19</a>]
            </dt>
            <dd>
              Mrityunjay Kumar, Venkatesh Choppella, Sanjana Sunil, Sumaid Ali
              Syed, ‘A Learnable-by-Design (LEAD) Model for Designing Virtual
              Experiments for Computer Science Labs’, submitted to{" "}
              <em>ITiCSE 2019</em>, 12 to 17 July 2019.
            </dd>
            <dt>
              [<a name="2018-icalt-kumar-et-al">18</a>]
            </dt>
            <dd>
              Mrityunjay Kumar, Jessica Emory, and Venkatesh Choppella.
              Usability analysis of virtual labs. In{" "}
              <em>
                2018 IEEE 18th International Conference on Advanced Learning
                Technologies (ICALT)
              </em>
              , pages 238--240. IEEE, July 2018. [&nbsp;
              <a href="all_bib.html#2018-icalt-kumar-et-al">bib</a>&nbsp;|
              <a href="http://dx.doi.org/10.1109/ICALT.2018.00061">DOI</a>
              &nbsp;]
            </dd>
            <dt>
              [<a name="2018-icalt-swain-et-al">17</a>]
            </dt>
            <dd>
              Shovan Swain, Lalit Sanagavarapu, Venkatesh Choppella, and
              Y.&nbsp;Raghu Reddy. Model driven approach for virtual lab
              authoring - chemical sciences labs. In{" "}
              <em>
                International Conference on Advanced Learning Technologies
                (ICALT)
              </em>
              . IEEE, 2018. Accepted for publication. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#2018-icalt-swain-et-al">
                bib
              </a>
              &nbsp;]
            </dd>
            <dt>
              [<a name="bandi-et-al-t4e-2011">16</a>]
            </dt>
            <dd>
              K.C. Bandi, A.K. Nori, V.&nbsp;Choppella, and S.&nbsp;Kode. A
              virtual laboratory for teaching linux on the web. In{" "}
              <em>
                IEEE 3rd International Conference on Technology for Education
              </em>
              , pages 212--215. IEEE Press, 2011. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#bandi-et-al-t4e-2011">
                bib
              </a>
              &nbsp;|
              <a href="https://faculty.iiit.ac.in/~vxc/research/papers/2011-t4e-linux.pdf">
                .pdf
              </a>
              &nbsp;]
            </dd>
            <dt>
              [<a name="khot-choppella-t4e-2011">15</a>]
            </dt>
            <dd>
              Rohit Khot and Venkatesh Choppella. Discovir: A framework for
              designing interfaces and structuring content for virtual labs. In{" "}
              <em>
                IEEE 3rd International Conference on Technology for Education
              </em>
              , pages 121--127. IEEE Press, 2011. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#khot-choppella-t4e-2011">
                bib
              </a>
              &nbsp;]
            </dd>

            <dt>
              [<a name="choppella-et-al-t4e-2011">14</a>]
            </dt>
            <dd>
              Venkatesh Choppella., V&nbsp;K Brahmajosyula, M.&nbsp;Vutpala.,
              and S.&nbsp;Kole. Process models for virtual lab development,
              deployment and distribution. In{" "}
              <em>
                IEEE 3rd International Conference on Technology for Education
              </em>
              , pages 293--294. IEEE Press, 2011. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#choppella-et-al-t4e-2011">
                bib
              </a>
              &nbsp;]
            </dd>
            <dt>
              [<a name="malini-et-al-t4e-2012">13</a>]
            </dt>
            <dd>
              S.&nbsp;Malani, G.N.S. Prasanna, J.A. del Alamo, J.L. Hardison,
              K.&nbsp;Moudgalya, and V.&nbsp;Chopella. Issues faced in a remote
              instrumentation laboratory. In{" "}
              <em>
                IEEE 4th International Conference on Technology for Education
              </em>
              , pages 67--74. IEEE Press, 2012. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#malini-et-al-t4e-2012">
                bib
              </a>
              &nbsp;]
            </dd>
            <dt>
              [<a name="Chatterjee-et-al-t4e-2013">12</a>]
            </dt>
            <dd>
              Sourav Chatterjee, Pranitha Reddy, and Venkatesh Choppella.
              Automated restructuring of contents for virtual labs. In{" "}
              <em>
                Proceedings of the IEEE International Conference on Technology
                for Education (T4E2013)
              </em>
              . IEEE Press, 2013. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#Chatterjee-et-al-t4e-2013">
                bib
              </a>
              &nbsp;]
            </dd>
            <dt>
              [<a name="2014-t4e-choudhary-et-al">11</a>]
            </dt>
            <dd>
              Nurendra Choudhary, Venkatesh Choppella, Raghu Reddy, and Thirumal
              Ravula. Large scale web page optimization of virtual labs. In{" "}
              <em>
                Proceedings of the IEEE International Conference on Technology
                for Education (T4E2014)
              </em>
              , pages 29--31. IEEE, December 2014. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#2014-t4e-choudhary-et-al">
                bib
              </a>
              &nbsp;]
            </dd>

            <dt>
              [<a name="2014-t4e-agarwal-et-al">10</a>]
            </dt>
            <dd>
              Jatin Agarwal, Utkarsh Rastogi, Prateek Pandey, Nurendra
              Choudhary, Venkatesh Choppella, and Raghu Reddy. Large scale web
              page optimization of virtual labs. In{" "}
              <em>
                Proceedings of the IEEE International Conference on Technology
                for Education (T4E2014)
              </em>
              , pages 146--147. IEEE, 2014. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#2014-t4e-agarwal-et-al">
                bib
              </a>
              &nbsp;]
            </dd>
            <dt>
              [<a name="Kelkar-et-al-icalt-2015">9</a>]
            </dt>
            <dd>
              Tejaswinee Kelkar, Anon Ray, and Venkatesh Choppella. Sangeetkosh:
              An open web platform for hindustani music education. In{" "}
              <em>
                Proceedings of the 15 IEEE Conference on Advances in Learning
                Technologies
              </em>
              , ICALT 2015, pages 5--9. IEEE, July 2015. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#Kelkar-et-al-icalt-2015">
                bib
              </a>
              &nbsp;]
            </dd>

            <dt>
              [<a name="Ahuja-et-al-icalt-2015">8</a>]
            </dt>
            <dd>
              Garima Ahuja, Anubha Gupta, Harsh Wardhan, and Venkatesh
              Choppella. Assessing the impact of virtual labs: a case study with
              the lab on advanced vlsi. In{" "}
              <em>
                Proceedings of the 15 IEEE Conference on Advances in Learning
                Technologies
              </em>
              , ICALT 2015, pages 290--292. IEEE, July 2015. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#Ahuja-et-al-icalt-2015">
                bib
              </a>
              &nbsp;|
              <a href="http://dx.doi.org/10.1109/ICALT.2015.41">DOI</a>&nbsp;|
              <a href="https://faculty.iiit.ac.in/~vxc/research/papers/2015-icalt-garima.pdf">
                .pdf
              </a>
              &nbsp;]
            </dd>

            <dt>
              [<a name="naidu-et-al-t4e-2010">7</a>]
            </dt>
            <dd>
              Thulasiram&nbsp;Naidu P, Manisha Verma, Venkatesh Choppella, and
              Gangadhar Chalapaka. Synthesizing customizable learning
              environments. In{" "}
              <em>
                2nd IEEE International Conference on Technology for Education
              </em>
              , July 2010. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#naidu-et-al-t4e-2010">
                bib
              </a>
              &nbsp;|
              <a href="https://faculty.iiit.ac.in/~vxc/research/papers/2010-t4e-naidu.pdf">
                .pdf
              </a>
              &nbsp;]
            </dd>

            <dt>
              [<a name="Choppella-t4e-tutorial-2010">6</a>]
            </dt>
            <dd>
              Venkatesh Choppella. Foss, web2.0 and mashups as a natural
              learning management infrastructure. In{" "}
              <em>IEEE Conf. on Technology for Education</em>, July 2010.
              Conference tutorial. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#Choppella-t4e-tutorial-2010">
                bib
              </a>
              &nbsp;]
            </dd>
            <dt>
              [<a name="khare-et-al-t4e-2012">5</a>]
            </dt>
            <dd>
              Sankalp Khare, Ishan Misra, and Venkatesh Choppella. Using
              org-mode and subversion for managing and publishing content in
              computer science courses. In{" "}
              <em>
                IEEE 4th International Conference on Technology for Education
              </em>
              , pages 220--223. IEEE Press, 2012. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#khare-et-al-t4e-2012">
                bib
              </a>
              &nbsp;]
            </dd>
            <dt>
              [<a name="mavalankar-et-al-t4e-2015">4</a>]
            </dt>
            <dd>
              Aditi Mavalankar, Tejaswinee Kelkar, and Venkatesh Choppella.
              Generation of quizzes and solutions based on ontologies -- a case
              for a music problem generator. In{" "}
              <em>
                2015 IEEE Seventh International Conference on Technology for
                Education (T4E)
              </em>
              , pages 73--76. IEEE, December 2015. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#mavalankar-et-al-t4e-2015">
                bib
              </a>
              &nbsp;|
              <a href="http://dx.doi.org/10.1109/T4E.2015.16">DOI</a>&nbsp;|
              <a href="https://faculty.iiit.ac.in/~vxc/research/papers/2015-t4e.pdf">
                .pdf
              </a>
              &nbsp;]
            </dd>

            <dt>
              [<a name="choppella-et-al-t4e-2012">3</a>]
            </dt>
            <dd>
              Venkatesh Choppella, Hitesh Kumar, P.&nbsp;Manjula, and
              K.&nbsp;Viswanath. From high-school algebra to computing through
              functional programming. In{" "}
              <em>
                IEEE 4th International Conference on Technology for Education
              </em>
              , pages 180--183. IEEE Press, 2012. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#choppella-et-al-t4e-2012">
                bib
              </a>
              &nbsp;]
            </dd>

            <dt>
              [<a name="Choppella-et-al-2016">2</a>]
            </dt>
            <dd>
              V.&nbsp;Choppella, G.&nbsp;Ahuja, and A.&nbsp;Mavalankar. How does
              a program run? a visual model based on annotating abstract syntax
              trees. In{" "}
              <em>
                2016 International Conference on Learning and Teaching in
                Computing and Engineering (LaTICE)
              </em>
              , pages 38--42, March 2016. [&nbsp;
              <a
                href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#Choppella-et-al-2016"
                target="_blank"
              >
                bib
              </a>
              &nbsp;|
              <a
                href="http://dx.doi.org/10.1109/LaTiCE.2016.40"
                target="_blank"
              >
                DOI
              </a>
              &nbsp;]
            </dd>

            <dt>
              [<a name="Manjula-et-al-t4e-2013">1</a>]
            </dt>
            <dd>
              Venkatesh Choppella, K&nbsp;Viswanath, and P&nbsp;Manjula. Viewing
              algorithms as iterative systems and plotting their dynamic
              behaviour. In{" "}
              <em>
                Proceedings of the IEEE International Conference on Technology
                for Education (T4E2013)
              </em>
              , T4E 2013, pages 206--209. IEEE Press, 2013. [&nbsp;
              <a href="https://faculty.iiit.ac.in/~vxc/research/all_bib.html#Manjula-et-al-t4e-2013">
                bib
              </a>
              &nbsp;|
              <a href="https://faculty.iiit.ac.in/~vxc/research/papers/2013-t4e-choppell-et-al.pdf">
                .pdf
              </a>
              &nbsp;]
            </dd>
          </dl>
        </section>
      </div>
    </Layout>
  );
};

export default Research;
